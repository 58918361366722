const SCRIPT_ID = "ublo-cms-module";

export async function load(customStructure) {
  const ubloModule = document.getElementById(SCRIPT_ID);
  const baseConfig = window.CmsConfig || {};
  const config = getConfig(customStructure);
  window.CmsConfig = { ...baseConfig, ...config };
  if (ubloModule) return Promise.resolve();
  return new Promise((resolve) => {
    const script = Object.assign(document.createElement("script"), {
      id: SCRIPT_ID,
      type: "module",
      src: "https://ublo.valraiso.net/cms/v2/cms.js",
      onload: resolve,
    });
    document.body.appendChild(script);
  });
}

export function getConfig(customStructure = []) {
  return {
    formats: "/cms/formats.json",
    styles: {},
    filemanager: {
      url: "https://ublo-file-manager.valraiso.net",
      token: "s7bj3fjo85it8o4dbxcj751ulo0652",
    },
    cdn: "https://ublo-file-manager.valraiso.net/assets/moncarnetderoute",
    customStructure,
    disablePageUi: true,
  };
}
